import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Button, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Menu from 'components/Menu';
import InfoIcon from '@material-ui/icons/Info';

import logo from './logo.png';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    // width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  left: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    marginLeft: '2rem',

    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  right: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    marginLeft: '2rem',

    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  logo: {
    height: '2.2rem',
  },
  retour: {
    fontWeight: 'bold',
    color: '#707070',
  },
}));

const Header = ({ retour, onHelpClick }) => {
  const classes = useStyles();
  const [open, setOpen] = useState();

  return (
    <>
      <div className={classes.header}>
        {retour ? (
          <>
            <Button
              component={Link}
              to={retour}
              className={clsx(classes.left, classes.retour)}
              startIcon={<ArrowBackIosIcon />}
            >
              back
            </Button>
          </>
        ) : (
          <IconButton className={classes.left} onClick={() => setOpen(true)}>
            <MenuIcon fontSize="large" />
          </IconButton>
        )}
        <img className={classes.logo} src={logo} alt="Logo" />
        {onHelpClick && (
          <IconButton className={classes.right} onClick={onHelpClick}>
            <InfoIcon />
          </IconButton>
        )}
      </div>
      <Drawer
        type="temporary"
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Menu />
      </Drawer>
    </>
  );
};

export default Header;
