import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    padding: '0.5rem 0.7rem',
    maxWidth: '12rem',
    letterSpacing: 0.6,
    textTransform: 'capitalize',

    // '& *': {
    //   color: 'white',
    // },

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const MyButton = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.button, className)}
      variant="contained"
      color="secondary"
      {...props}
    />
  );
};

export default MyButton;
