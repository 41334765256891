import React, { Suspense } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppProvider } from 'context';

import Loading from './components/Loading';
import Decks from './components/Decks';
import Hub from './components/Hub';
import Site from './components/Site';
import Bacteria from './components/Bacteria';
import Special from './components/Special';
import Antibio from './components/Antibio';
import Patient from './components/Patient';
import APropos from './components/APropos';
import theme from './theme';

const useStyles = makeStyles(theme => ({
  '@global': {
    '*': {
      fontFamily: '"Arial", sans-serif',
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      background: '#F8F7FC',
      minWidth: 320,
    },
    p: {
      color: '#5D4F98',
    },
    h1: {
      margin: 0,
      marginBottom: '0.5rem',
      marginTop: '2rem',
      color: '#5D4F98',
      fontSize: '1.3rem',
      letterSpacing: 1,
      textAlign: 'center',
    },
    h2: {
      color: '#5D4F98',
      fontSize: '0.8rem',
      fontStyle: 'italic',
      fontWeight: 'normal',
      textAlign: 'center',
      letterSpacing: 0.6,
    },
    button: {
      fontWeight: 700,
    },
    a: {
      color: '#5D4F98',
    },
    '.swal2-close:focus': {
      boxShadow: 'none',
    },
  },
  wrapper: {
    maxWidth: 1200,
    margin: '0 auto',
    minHeight: '100vh',
    background: '#F8F7FC',
    padding: '1rem',
    display: 'flex',
    flexFlow: 'column',
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Suspense fallback={<Loading />}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Decks />
            </Route>
            <Route exact path="/about">
              <APropos />
            </Route>
            <Route exact path="/hub">
              <Hub />
            </Route>
            <Route exact path="/bacteria">
              <Bacteria />
            </Route>
            <Route exact path="/site">
              <Site />
            </Route>
            <Route exact path="/special">
              <Special />
            </Route>
            <Route exact path="/antibio">
              <Antibio />
            </Route>
            <Route exact path="/patient">
              <Patient />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

const AppWithProviders = () => (
  <ThemeProvider theme={theme}>
    <AppProvider>
      <App />
    </AppProvider>
  </ThemeProvider>
);

export default AppWithProviders;
