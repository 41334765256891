import React from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '1rem',
    margin: 'auto',
    maxWidth: '40rem',

    '& img': {
      width: '80%',
      display: 'block',
      margin: 'auto',
    },
  },
}));
const RichText = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};

export default RichText;
