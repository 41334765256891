import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import BgViolet from './bg_violet.png';
import BgVioletClair from './bg_violet_clair.png';
import BgGrisClair from './bg_gris_clair.png';
import BgWhite from './bg_white.png';
import Badge from './badge.png';
import Check from './check.png';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: ({ width }) => width,
    height: ({ width }) => width,
    position: 'relative',

    '& > div': {
      zIndex: 2,
    },
  },
  white: {
    width: ({ width }) => width,
    transform: 'translate(50%,-50%)',
    position: 'absolute',
    top: '50%',
    right: '50%',
    zIndex: 1,
  },
  violet: {
    width: ({ width }) => width * 0.8,
    transform: 'translate(50%,-50%)',
    position: 'absolute',
    top: '50%',
    right: '50%',
    zIndex: 2,
    paddingBottom: ({ width }) => width / 15,
  },
  badge: {
    position: 'absolute',
    right: 0,
    bottom: -5,

    '& > *': {
      width: ({ width }) => `${(2.2 * width) / 100}rem`,
    },
  },
  check: {
    position: 'absolute',
    top: '35%',
    left: '50%',
    width: ({ width }) => `${(1.2 * width) / 100}rem`,
    transform: 'translate(-50%,-50%)',
  },
}));

const CircleElement = ({
  width,
  className,
  color,
  children,
  checked,
  ...props
}) => {
  const classes = useStyles({ width });

  const src = {
    gris_clair: BgGrisClair,
    violet_clair: BgVioletClair,
    violet: BgViolet,
  }[color];

  return (
    <div className={clsx(classes.wrapper, className)} {...props}>
      <img className={classes.white} src={BgWhite} />
      <img className={classes.violet} src={src} />
      {children}
      {checked && (
        <div className={classes.badge}>
          <img src={Badge} />
          <img src={Check} className={classes.check} />
        </div>
      )}
    </div>
  );
};

export default CircleElement;
