import { createMuiTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#8e8bbe',
      main: '#5D4F98',
      dark: '#3f2a68',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f690bc',
      main: '#E10072',
      dark: '#8f005d',
      contrastText: '#fff',
    },
  },
});

export default theme;
