import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/Button';
import RichText from 'components/RichText';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',

    '& > img': {
      width: 80,
      margin: 0,
      marginBottom: '1.5rem',
    },

    '& > h1': {
      margin: 0,
      marginBottom: '1.5rem',
    },
  },

  text: {
    color: '#5D4F98',
    marginBottom: '0.5rem',
    fontWeight: 'bold',
  },
  reference: {
    fontSize: '0.8rem',
    marginBottom: '0.5rem',
    marginLeft: '-2rem',
    marginRight: '-2rem',
  },
}));

const Modal = ({ title, text, reference, image, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img src={image} />
      <h1>{title}</h1>
      <span className={classes.text}>{text}</span>
      <div className={classes.reference}>
        <RichText text={reference} />
      </div>
      <Button onClick={onClick}>Nouveau test</Button>
    </div>
  );
};

export default Modal;
