import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, ButtonBase } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useDecks from 'hooks/useDecks';
import { AppContext } from 'context';
import Header from 'components/Header';
import Selector from 'components/Selector';
import Stepper from './Stepper';

const useStyles = makeStyles(theme => ({
  wrapper: {},
  octogonImage: {
    width: '100%',
  },
  italic: {
    fontStyle: 'italic',
  },
}));

const Bacteria = () => {
  const classes = useStyles();
  const [aspect, setAspect] = useState();
  const [type, setType] = useState();
  const history = useHistory();

  const {
    choices,
    setChoices,
    criteria: { loading, value: criteria },
  } = useContext(AppContext);

  // const history = useHistory();
  // // const setChoices = useSetRecoilState(choicesAtom);
  // const { value: decks, loading } = useDecks();
  // const { setChoices } = useContext(AppContext);

  // if (loading) return null;

  const handleComplete = resistance => {
    if (aspect != null && resistance && type != null) {
      setChoices({
        ...choices,
        bacteria: {
          aspect,
          resistance,
          type,
        },
      });
      history.push('/hub');
    }
  };

  if (loading) return null;

  if (!criteria) {
    history.push('/');
    return null;
  }

  return (
    <>
      <Header retour="/hub" />

      <div className={classes.wrapper}>
        <h1>Select a bacteria</h1>
        <Stepper
          values={[
            {
              label: 'Morphology',
              renderChild: () => (
                <img
                  className={classes.octogonImage}
                  src={
                    aspect != null &&
                    process.env.REACT_APP_IMAGE_BASE_URL +
                      criteria.bacteriaMorphologies.find(
                        ({ id }) => aspect === id,
                      ).image.url
                  }
                />
              ),
              checked: aspect != null,
              onClick:
                aspect != null
                  ? () => {
                      setType();
                      setAspect();
                    }
                  : null,
            },
            {
              label: 'Species',
              renderChild: () => (
                <div className={classes.octogonType}>
                  {type &&
                    criteria.bacteriaSpecies.find(({ id }) => type === id).abbr}
                </div>
              ),
              checked: type != null,
              onClick:
                type != null
                  ? () => {
                      setType();
                    }
                  : null,
            },
            {
              label: 'Resistance',
              renderChild: () => null,
            },
          ]}
        />
        {!aspect ? (
          <Selector
            entities={criteria.bacteriaMorphologies}
            onSelect={e => {
              setAspect(e);
            }}
            renderChild={({ image, label }) => (
              <>
                {image.url && (
                  <div>
                    <img
                      src={process.env.REACT_APP_IMAGE_BASE_URL + image.url}
                    />
                  </div>
                )}
                {label && <h1>{label}</h1>}
              </>
            )}
          />
        ) : !type ? (
          <Selector
            entities={criteria.bacteriaSpecies.filter(
              e => e.bacteria_morphology.id === aspect,
            )}
            onSelect={e => {
              setType(e);
            }}
            renderChild={({ label }) => (
              <h1 className={classes.italic}>{label}</h1>
            )}
          />
        ) : (
          <Selector
            entities={
              criteria.bacteriaSpecies.find(({ id }) => id === type)
                .bacteria_resistances
            }
            onSelect={e => {
              handleComplete(e);
            }}
            renderChild={({ label }) => <h1>{label}</h1>}
          />
        )}
      </div>
    </>
  );
};

export default Bacteria;
