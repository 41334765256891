import React, { useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, ButtonBase } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useDecks from 'hooks/useDecks';
import { AppContext } from 'context';
import Header from 'components/Header';
import Selector from 'components/Selector';

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& > h2': {
      margin: '1.5rem 0',
    },
  },
}));

const Site = () => {
  const classes = useStyles();

  const history = useHistory();

  const {
    choices,
    setChoices,
    criteria: { loading, value: criteria },
  } = useContext(AppContext);

  const handleComplete = site => {
    if (site != null) {
      setChoices({
        ...choices,
        site,
      });
      history.push('/hub');
    }
  };

  if (loading) return null;

  if (!criteria) {
    history.push('/');
    return null;
  }

  return (
    <>
      <Header retour="/hub" />

      <div className={classes.wrapper}>
        <h1>Select an infection site</h1>

        <Selector
          entities={criteria.infectiousSites}
          onSelect={handleComplete}
          renderChild={({ image, label }) => (
            <>
              {image.url && (
                <div>
                  <img src={process.env.REACT_APP_IMAGE_BASE_URL + image.url} />
                </div>
              )}
              {label && <h1>{label}</h1>}
            </>
          )}
        />
      </div>
    </>
  );
};

export default Site;
