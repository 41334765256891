import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Logo from './logo.png';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '10rem',

    '& nav > *': {
      textAlign: 'center',
    },
  },
  logo: {
    '& > img': {
      margin: '1rem auto',
      width: '80%',
      maxWidth: '5rem',
      display: 'block',
    },
  },
}));

const Menu = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.logo}>
        <img src={Logo} />
      </div>
      <Divider />
      <List component="nav">
        <ListItem button component={Link} to="/">
          <ListItemText>Home</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/about">
          <ListItemText>About us</ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

export default Menu;
