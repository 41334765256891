import React, { useContext } from 'react';
import { AppContext } from 'context';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import swal from 'swal';
import api from 'api';

import Button from 'components/Button';
import Header from 'components/Header';
import Modal from 'components/Modal';
import RichText from 'components/RichText';

import HubCircleElement from './HubCircleElement';

// import poubelle from './poubelle.png';
// import erlenmeyer from './erlenmeyer.png';
import { ReactComponent as Poubelle } from './poubelle.svg';
import { ReactComponent as Erlenmeyer } from './erlenmeyer.svg';
import site from './site.png';
import bacterie from './bacterie.png';
import patient from './patient.png';
import antibio from './antibio.png';
import special from './special.png';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    flex: 'auto',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  circle: {
    position: 'relative',
    margin: 'auto 0',
    height: ({ radius }) => 3 * radius,

    '& > *:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    width: '1rem',
  },
  buttons: {
    display: 'flex',
    width: '100%',
    marginBottom: '2rem',
    justifyContent: 'center',

    '& > *': {
      flex: 1,
      margin: '0 0.5rem',
    },
  },
}));

const Hub = () => {
  const {
    meta,
    choices,
    setChoices,
    criteria: { loading, value: criteria },
  } = useContext(AppContext);

  const history = useHistory();
  const radius = 100;
  const circleWidth = 100;

  const classes = useStyles({ radius });

  if (!choices?.deck) {
    return <Redirect to="/" />;
  }

  const handleCircleClick = ({ to }) => {
    if (loading) return;
    history.push(to);
  };

  const handleTest = async () => {
    const params = { ...choices };
    if (choices.bacteria) {
      params.bacteria_resistance = choices.bacteria.resistance;
      params.bacteria = choices.bacteria.type;
    }

    if (choices.antibio) {
      params.antibio = choices.antibio.antibio;
    }

    const {
      data: { result, reference },
    } = await api.post(`/dawapp`, params);

    if (reference == null && result == null) {
      swal.fire({
        html: (
          <Modal
            title="Oops ! No results :("
            text="Try another combination"
            onClick={() => {
              swal.close();
              setChoices();
            }}
          />
        ),
        showConfirmButton: false,
        showCloseButton: true,
      });
      return;
    }

    const { image, label, text } = result
      ? meta.result.find(e => e.value === result)
      : {
          image: null,
          label: 'Reference',
          text: '',
        };

    swal.fire({
      html: (
        <Modal
          title={label}
          text={text}
          image={
            image ? process.env.REACT_APP_IMAGE_BASE_URL + image.url : null
          }
          reference={reference ? reference.text : ''}
          onClick={() => {
            swal.close();
            setChoices();
          }}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <>
      <Header
        retour="/"
        onHelpClick={() =>
          swal.fire({
            html: <RichText text={meta.aide} />,
            showConfirmButton: false,
            showCloseButton: true,
          })
        }
      />

      <div className={classes.wrapper}>
        <h1>Select your combination</h1>

        <div className={classes.circle}>
          <HubCircleElement
            color={choices.site ? 'violet' : 'violet_clair'}
            checked={choices.site != null}
            onClick={() => handleCircleClick({ to: 'site' })}
            disabled={loading}
            deg={-72 + 0}
            radius={radius}
            width={circleWidth}
            label={
              choices.site != null
                ? criteria.infectiousSites.find(({ id }) => id === choices.site)
                    .abbr
                : 'Infection site'
            }
            icon={
              choices.site != null
                ? process.env.REACT_APP_IMAGE_BASE_URL +
                  criteria.infectiousSites.find(({ id }) => id === choices.site)
                    .image.url
                : bacterie
            }
          />
          <HubCircleElement
            color={choices.bacteria ? 'violet' : 'violet_clair'}
            checked={choices.bacteria}
            onClick={() => handleCircleClick({ to: '/bacteria' })}
            disabled={loading}
            deg={-72 + 72}
            radius={radius}
            width={circleWidth}
            label={
              choices.bacteria
                ? criteria.bacteriaSpecies.find(
                    ({ id }) => id === choices.bacteria.type,
                  ).abbr
                : 'Bacteria'
            }
            icon={
              choices.bacteria
                ? process.env.REACT_APP_IMAGE_BASE_URL +
                  criteria.bacteriaMorphologies.find(
                    ({ id }) => id === choices.bacteria.aspect,
                  ).image.url
                : site
            }
          />
          <HubCircleElement
            color={choices.patient ? 'violet' : 'violet_clair'}
            checked={choices.patient != null}
            onClick={() => handleCircleClick({ to: '/patient' })}
            disabled={loading}
            deg={-72 + 144}
            radius={radius}
            width={circleWidth}
            label={
              choices.patient != null
                ? criteria.backgrounds.find(({ id }) => id === choices.patient)
                    .abbr
                : 'Patient background'
            }
            icon={
              choices.patient != null
                ? process.env.REACT_APP_IMAGE_BASE_URL +
                  criteria.backgrounds.find(({ id }) => id === choices.patient)
                    .image.url
                : patient
            }
          />
          <HubCircleElement
            color={choices.antibio ? 'violet' : 'violet_clair'}
            checked={choices.antibio != null}
            onClick={() => handleCircleClick({ to: '/antibio' })}
            disabled={loading}
            deg={-72 + 216}
            radius={radius}
            width={circleWidth}
            label={
              choices.antibio != null
                ? criteria.antibiotics.find(
                    ({ id }) => id === choices.antibio.antibio,
                  ).abbr
                : 'Antibiotic'
            }
            icon={
              choices.antibio != null
                ? process.env.REACT_APP_IMAGE_BASE_URL +
                  criteria.antibioticFamilies.find(
                    ({ id }) => id === choices.antibio.family,
                  ).image.url
                : antibio
            }
          />
          <HubCircleElement
            color={choices.special ? 'violet' : 'violet_clair'}
            checked={choices.special != null}
            onClick={() => handleCircleClick({ to: '/special' })}
            disabled={loading}
            deg={-72 + 288}
            radius={radius}
            width={circleWidth}
            label={
              choices.special != null
                ? criteria.specialCards.find(({ id }) => id === choices.special)
                    .abbr
                : 'Special card'
            }
            icon={
              choices.special != null
                ? process.env.REACT_APP_IMAGE_BASE_URL +
                  criteria.specialCards.find(({ id }) => id === choices.special)
                    .image.url
                : special
            }
          />
        </div>
        <div className={classes.buttons}>
          <Button
            disabled={
              choices.site == null &&
              choices.bacteria == null &&
              choices.patient == null &&
              choices.antibio == null &&
              choices.special == null
            }
            startIcon={<Poubelle />}
            variant="outlined"
            color="primary"
            onClick={() => {
              setChoices({
                deck: choices.deck,
              });
            }}
          >
            Reset
          </Button>
          <Button
            disabled={choices.site == null || choices.patient == null}
            startIcon={<Erlenmeyer />}
            onClick={handleTest}
          >
            Test
          </Button>
        </div>
      </div>
    </>
  );
};

export default Hub;
