import React, { useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, ButtonBase } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useDecks from 'hooks/useDecks';
import Stepper from './Stepper';
import { AppContext } from 'context';
import Header from 'components/Header';
import Selector from 'components/Selector';

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& > h1': {
      marginBottom: '1.5rem',
    },
  },
  octogonImage: {
    width: '100%',
  },
}));

const Antibio = () => {
  const classes = useStyles();
  const history = useHistory();
  const [family, setFamily] = useState();

  const {
    choices,
    setChoices,
    criteria: { loading, value: criteria },
  } = useContext(AppContext);

  const handleComplete = antibio => {
    if (family != null && antibio != null) {
      setChoices({
        ...choices,
        antibio: {
          family,
          antibio,
        },
      });
      history.push('/hub');
    }
  };

  if (loading) return null;

  if (!criteria) {
    history.push('/');
    return null;
  }

  return (
    <>
      <Header retour="/hub" />

      <div className={classes.wrapper}>
        <h1>Select an antibiotic</h1>
        <Stepper
          values={[
            {
              label: 'Mechanism',
              renderChild: () => (
                <div>
                  <img
                    className={classes.octogonImage}
                    src={
                      family != null &&
                      process.env.REACT_APP_IMAGE_BASE_URL +
                        criteria.antibioticFamilies.find(
                          ({ id }) => family === id,
                        ).image.url
                    }
                  />
                </div>
              ),
              checked: family != null,
              onClick:
                family != null
                  ? () => {
                      setFamily();
                    }
                  : null,
            },
            {
              label: 'Antibiotic',
              renderChild: () => null,
            },
          ]}
        />

        {!family ? (
          <Selector
            entities={criteria.antibioticFamilies}
            onSelect={e => {
              setFamily(e);
            }}
            renderChild={({ image, label }) => (
              <>
                {image.url && (
                  <div>
                    <img
                      src={process.env.REACT_APP_IMAGE_BASE_URL + image.url}
                    />
                  </div>
                )}
                {label && <h1>{label}</h1>}
              </>
            )}
          />
        ) : (
          <Selector
            entities={criteria.antibiotics.filter(
              e => e.antibiotic_family.id === family,
            )}
            onSelect={handleComplete}
            renderChild={({ label }) => <h1>{label}</h1>}
          />
        )}
      </div>
    </>
  );
};

export default Antibio;
