import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Octogon from './Octogon';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '1.5rem 0',
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Stepper = ({ values }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {values.map(({ label, renderChild, checked, onClick }) => (
        <Octogon
          label={label}
          checked={checked}
          renderChild={renderChild}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

export default Stepper;
