import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from 'context';

import Header from 'components/Header';
import RichText from 'components/RichText';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '2rem auto',
    maxWidth: '40rem',

    '& img': {
      width: '80%',
      display: 'block',
      margin: 'auto',
    },
  },
}));

const Decks = () => {
  const classes = useStyles();
  // const setChoices = useSetRecoilState(choicesAtom);

  const { meta } = useContext(AppContext);

  if (!meta) return null;

  return (
    <>
      <Header retour="/" />

      <RichText text={meta.a_propos} />
    </>
  );
};

export default Decks;
