import React, { createContext, useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import api from 'api';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [choices, setChoices] = useState();
  const [meta, setMeta] = useState();

  useEffect(() => {
    api.get(`/meta`).then(({ data }) => setMeta(data));

    // Pour faire des tests
    //   setTimeout(() => {
    //     setChoices({
    //       deck: 2,
    //       patient: 4,
    //       antibio: { family: 2, antibio: 6 },
    //       bacteria: { aspect: 1, resistance: 1, type: 1 },
    //       site: 1,
    //       special: 2,
    //     });
    //   }, 2000);
    // }, []);
  }, []);

  const criteria = useAsync(async () => {
    try {
      if (choices?.deck == null) {
        return null;
      }

      setChoices({
        deck: choices.deck,
      });

      let [
        { data: antibiotics },
        { data: backgrounds },
        { data: references },
        { data: bacteriaSpecies },
        { data: infectiousSites },
        { data: specialCards },
      ] = await Promise.all([
        api.get(`/antibiotics?_limit=-1&decks_contains=${choices.deck}`),
        api.get(`/backgrounds?_limit=-1&decks_contains=${choices.deck}`),
        api.get(`/references?_limit=-1&decks_contains=${choices.deck}`),
        api.get(`/bacteria-species?_limit=-1&decks_contains=${choices.deck}`),
        api.get(`/infectious-sites?_limit=-1&decks_contains=${choices.deck}`),
        api.get(`/special-cards?_limit=-1&decks_contains=${choices.deck}`),
      ]);

      bacteriaSpecies = bacteriaSpecies.filter(({ slug }) => slug !== 'null');
      antibiotics = antibiotics.filter(({ slug }) => slug !== 'null');
      specialCards = specialCards.filter(({ slug }) => slug !== 'null');

      const bacteriaMorphologies = Object.values(
        bacteriaSpecies
          .map(({ bacteria_morphology }) => bacteria_morphology)
          .reduce((acc, e) => {
            acc[e.id] = e;
            return acc;
          }, {}),
      );

      const antibioticFamilies = Object.values(
        antibiotics
          .map(({ antibiotic_family }) => antibiotic_family)
          .reduce((acc, e) => {
            acc[e.id] = e;
            return acc;
          }, {}),
      );

      return {
        antibioticFamilies,
        antibiotics,
        backgrounds,
        references,
        bacteriaMorphologies,
        bacteriaSpecies,
        infectiousSites,
        specialCards,
      };
    } catch (e) {
      console.error(e);
    }
  }, [choices?.deck]);

  return (
    <AppContext.Provider
      value={{
        criteria,
        choices,
        setChoices,
        meta,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
