import { useState, useEffect } from 'react';
import api from 'api';
import { useAsync } from 'react-use';

const useDecks = () => {
  const res = useAsync(async () => {
    return api.get('/decks').then(r => r.data);
  });

  return res;
};

export default useDecks;
