import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircleElement from 'components/CircleElement';

const useStyles = makeStyles(theme => ({
  icon: {
    transform: 'translate(50%,-50%)',
    position: 'absolute',
    top: '50%',
    right: '50%',

    width: '30%',

    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',

    '& img': {
      width: '100%',
    },

    '& p': {
      margin: 0,
      marginTop: '0.1rem',
      fontSize: '0.65rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  wrapper: {
    position: 'absolute',
  },
}));

const degToRad = deg => {
  return (deg * Math.PI) / 180;
};

const HubCircleElement = ({
  deg,
  radius,
  icon,
  label,
  color,
  checked,
  width,
  disabled,
  ...props
}) => {
  const [pos, setPos] = useState();
  const classes = useStyles();

  useEffect(() => {
    const angleRad = degToRad(deg - 90);
    const x = radius * Math.cos(angleRad);
    const y = radius * Math.sin(angleRad) + radius;
    setPos({
      x,
      y,
    });
  }, []);

  if (!pos) {
    return null;
  }

  return (
    <CircleElement
      width={width}
      className={classes.wrapper}
      color={color}
      style={{
        left: pos.x - width / 2,
        top: pos.y,
      }}
      checked={checked}
      {...props}
    >
      {disabled || (
        <div className={classes.icon}>
          <img src={icon} />
          <p>{label}</p>
        </div>
      )}
    </CircleElement>
  );
};

export default HubCircleElement;
