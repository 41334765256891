import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import { Paper, IconButton } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import Button from 'components/Button';

import { makeStyles } from '@material-ui/core/styles';

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

const useStyles = makeStyles(theme => ({
  wrapper: {
    flex: 1,
    margin: '0 -1rem',
    marginTop: 'auto',
    padding: '0rem 3.5rem',
  },
  slide: {
    padding: '0.5rem 0.5rem',
  },
  item: {
    padding: '1rem',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '17rem',
    maxWidth: '24rem',
    margin: 'auto',

    '& > *': {
      'flex-basis': 0,
    },

    '& > h1': {
      margin: 'auto 0',

      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },

    '& > div': {
      flex: 1.5,
      position: 'relative',
      alignSelf: 'stretch',

      '& > img': {
        maxHeight: '100%',
        maxWidth: '100%',
        right: '50%',
        top: '50%',
        transform: 'translate(50%, -50%)',
        position: 'absolute',
      },
    },
  },
  button: {
    minHeight: '35px',
    width: '8rem',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 1rem',
  },
}));

const Selector = ({ entities, onSelect, renderChild }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  if (!entities) return null;

  return (
    <>
      <div className={classes.head}>
        <IconButton
          disabled={index === 0}
          onClick={() => {
            setIndex(index - 1);
            setIndex(index - 1);
          }}
        >
          <ArrowBackIos />
        </IconButton>
        <IconButton
          disabled={index === entities.length - 1}
          onClick={() => {
            setIndex(index + 1);
            setIndex(index + 1);
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </div>
      <BindKeyboardSwipeableViews
        animateTransitions={false}
        className={classes.wrapper}
        slideClassName={classes.slide}
        index={index}
        enableMouseEvents
        onChangeIndex={index => {
          setIndex(index);
        }}
        containerStyle={{
          transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
        }}
      >
        {entities.map(e => (
          <Paper elevation={2} key={e.id} className={classes.item}>
            {renderChild(e)}
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => {
                onSelect(e.id);
                setIndex(0);
              }}
            >
              Choose
            </Button>
          </Paper>
        ))}
      </BindKeyboardSwipeableViews>
    </>
  );
};

export default Selector;
