import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircleElement from 'components/CircleElement';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '0 0.5rem',
    textAlign: 'center',
    // fontSize: '0.7rem',

    // '& img': {
    //   maxWidth: '80%',
    //   maxHeight: '80%',
    // },
  },
  content: {
    transform: 'translate(50%,-50%)',
    position: 'absolute',
    top: '50%',
    right: '50%',
    maxWidth: '80%',

    fontSize: ({ checked }) => (checked ? '0.7rem' : '2rem'),
    color: ({ checked }) => (checked ? '#5D4F98' : '#C6C5CE'),
  },
  label: {
    color: ({ checked }) => (checked ? '#5D4F98' : '#C6C5CE'),
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
}));

const Octogon = ({ label, checked, renderChild, onClick }) => {
  const classes = useStyles({ checked });

  return (
    <div
      className={clsx(classes.wrapper, onClick && classes.clickable)}
      onClick={onClick}
    >
      <CircleElement
        checked={checked}
        color={checked ? 'violet' : 'gris_clair'}
        width={80}
      >
        <div className={classes.content}>{checked ? renderChild() : '?'}</div>
      </CircleElement>
      <span className={classes.label}>{label}</span>
    </div>
  );
};

export default Octogon;
