import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, ButtonBase } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useDecks from 'hooks/useDecks';
import { AppContext } from 'context';
import Header from 'components/Header';

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'center',
    margin: 'auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',

    '& > *': {
      margin: '1.5rem',

      [theme.breakpoints.down('xs')]: {
        margin: '0.5rem',
      },
    },
  },
  button: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    color: '#707070',
    fontWeight: 600,
    letterSpacing: 1,
    padding: '2rem',

    height: '100%',

    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },

    '& > img': {
      margin: 'auto',
      width: '6rem',
    },
  },
  label: {
    marginBottom: '1rem',
  },
}));

const Decks = () => {
  const classes = useStyles();
  const history = useHistory();
  const { value: decks, loading } = useDecks();
  const { choices, setChoices } = useContext(AppContext);

  if (loading || !decks) return null;

  return (
    <>
      <Header />

      <div className={classes.wrapper}>
        <h1>Choose your deck</h1>

        <div className={classes.buttons}>
          {decks.map(deck => (
            <Paper elevation={3} key={deck.id}>
              <ButtonBase
                className={classes.button}
                focusRipple
                focusVisibleClassName={classes.focusVisible}
                onClick={() => {
                  console.log('??', deck.password);
                  if (deck.password) {
                    const guess = window.prompt('Password ?');
                    if (guess !== deck.password) {
                      window.alert('Wrong password');
                      return;
                    }
                  }

                  setChoices({
                    deck: deck.id,
                  });
                  history.push('/hub');
                }}
              >
                <span className={classes.label}>{deck.label}</span>
                <img
                  src={process.env.REACT_APP_IMAGE_BASE_URL + deck.logo.url}
                  alt={deck.label}
                />
              </ButtonBase>
            </Paper>
          ))}
        </div>
      </div>
    </>
  );
};

export default Decks;
